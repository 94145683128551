[class*='bodycopy-'] {
    font-family: var(--font-family-sans-low);
    letter-spacing: var(--font-letter-spacing-wide);
}
.bodycopy {
    &-l, &-L {
        font-size: var(--font-size-6);
        line-height: var(--font-line-height-4);
    }
    &-m, &-M {
        font-size: var(--font-size-5);
        line-height: var(--font-line-height-3);
    }
    &-s, &-S {
        font-size: var(--font-size-3);
        line-height: var(--font-line-height-2);
    }
    &-xs, &-XS {
        font-size: var(--font-size-2);
        line-height: var(--font-line-height-1);
    }
}
