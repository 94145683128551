.header {
    &__contact-info{
        .bodycopy-xs {
            margin-bottom:0;
        }
        .tooltip .icon {
            fill:white;
        }
    }
    &__head {
        &::after {
            z-index:-1;
        }
    }
    &__body{
        position:relative;
        &-wrapper {
            width:100%;
            flex-basis:100%;
            max-width:90%;
            justify-content: flex-start;
        }
    }
    .container__flex {
        flex-flow: nowrap;
    }
            
    &-main {
        &-list {
            display: flex;
            justify-content: flex-start;
            margin-right: 114px;
            width: 100%;
            flex-basis: 100%;
            max-width: 100%;
            flex-flow: nowrap;
        }
    }
    &-submenu {
        @media (--viewport-sm-min) {
            max-width:var(--site--width);
        }
    }
    .dropdown-menu__footer {
        padding: 0;
        &__cta-btn .btn {
            width:100%;
        }
    }
    &__body-main-profile {
        .header__body-main-item {
            .header__body-main-btn{
                color: inherit;
                padding: 20px 20px 20px 0;
            } 
        }
    }

}

